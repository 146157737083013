"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validate = void 0;
var _map = _interopRequireDefault(require("lodash/map"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * Validates values agains the provided Joi `schema`. Returns an object with a
 * property for each field that has an error and value of its description.
 *
 * @param {Object} schema     A `Joi` schema definition (https://joi.dev/api/?v=17.6.0)
 * @param {Object} values     Data to validate against the provided schema
 *
 * @return {Object}           An object with keys for each field that returned errors
 */
const validate = schema => async values => {
  var _validations$error;
  const errors = {};
  if (!schema) throw new Error("'schema' required to validate values");
  const validations = schema.validate(values, {
    abortEarly: false
  });
  const validationErrors = (validations === null || validations === void 0 ? void 0 : (_validations$error = validations.error) === null || _validations$error === void 0 ? void 0 : _validations$error.details) ?? [];
  (0, _map.default)(validationErrors, ({
    message,
    path
  }) => errors[path] = message);
  return errors;
};
exports.validate = validate;