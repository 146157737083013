"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.rgbToHex = exports.hexToRgb = void 0;
const rgbToHex = ({
  r,
  g,
  b
}) => "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
exports.rgbToHex = rgbToHex;
const hexToRgb = hex => {
  const rgb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return rgb ? {
    r: parseInt(rgb[1], 16),
    g: parseInt(rgb[2], 16),
    b: parseInt(rgb[3], 16)
  } : null;
};
exports.hexToRgb = hexToRgb;