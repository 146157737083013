import {BiTime, BiRightArrowAlt} from "react-icons/bi";

import styled from "styled-components";

import {AnimateEntrance} from "./shared/AnimateEntrance";
import {Button} from "./shared/Button";
import {ReactComponent as LogoSVG} from "../assets/logo.svg";

const Container = styled.div`
  background: linear-gradient(45deg, #08080a 0, #252831 100%);
  overflow: hidden;
  margin-bottom: -220px;

  @media (min-width: 640px) {
    margin-bottom: -320px;
  }
`;

const TimeIcon = styled(BiTime)`
  position: relative;
  top: 1px;
`;

const Img = styled.img`
  width: 100%;
  max-width: 610px;
`;

export const HeroTop = ({setSignUpModalOpen}) => (
  <Container className="z-0">
    <div className="container mx-auto p-2 pt-5 flex flex-row justify-between">
      <div className="cursor-default">
        <LogoSVG className="scale-95" />
      </div>
      <div className="m-4">
        <button
          onClick={() => setSignUpModalOpen(true)}
          className="
            p-3
            rounded-md
            bg-transparent
            hover:text-white
            text-slate-300
            transition-all
            font-semibold
            tracking-widest
            uppercase
            text-xs
            flex
            flex-row
          "
        >
          <TimeIcon className="mr-3" />
          Sign Up
        </button>
      </div>
    </div>
    <div className="container mx-auto p-6">
      <div className="text-left sm:text-center">
        <h1 className="sm:text-3xl xl:text-4xl text-2xl text-slate-50 pb-1 mt-1">
          Auto-organize your sound library
        </h1>

        <h1 className="sm:text-3xl xl:text-4xl text-2xl text-slate-50 pb-4 sm:mt-2">
          <span className="underline decoration-2 md:decoration-4 underline-offset-8 decoration-[#295abd]">
            Rediscover
          </span>{" "}
          your sounds
        </h1>
        
        <h2 className="sm:text-xl xl:text-2xl text-md text-slate-400 mt-2 sm:my-6 font-light text-left sm:text-center">
          {/* It is not next-generation yet. Still a lot of work to be done.
          don't want to disappoint users by over-promising here. Should be updated
          once we kick ass, though. */}
          {/* A next-generation browser, search engine, and intelligent catalog for
          all your samples and loops */}
          A new way to browse, search, and organize all your samples and loops
        </h2>
      </div>
      <div className="mt-10 mb-2 flex justify-center">
        <AnimateEntrance delay={0.25} duration={2.5}>
          <Button onClick={() => setSignUpModalOpen(true)}>
            Sign Up For the Free Beta
            <BiRightArrowAlt className="ml-2 text-base" />
          </Button>
        </AnimateEntrance>
      </div>
    </div>
    <div className="container mx-auto px-2.5 mb-12">
      <div className="flex justify-center flex-col grow items-center mb-6">
        <div className="z-10 relative">
          <AnimateEntrance duration={4.5}>
            <Img
              src="/assets/screenshots/screen-3-transparent-bg.png"
              alt="Resound Library screenshot showing file tree and search"
              width="850"
              height="710"
            />
          </AnimateEntrance>
        </div>
      </div>
    </div>
  </Container>
);
