"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AsyncUtils = void 0;
var _debounce = _interopRequireDefault(require("lodash/debounce"));
var _throttle = _interopRequireDefault(require("lodash/throttle"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const bindAsyncRunner = (func, task, wait) => {
  const run = func(task, wait);
  return (...args) => {
    var _run;
    return (_run = run(...args)) === null || _run === void 0 ? void 0 : _run.catch(error => Promise.reject(error));
  };
};
const AsyncUtils = {
  debounce: (task, wait) => bindAsyncRunner(_debounce.default, task, wait),
  throttle: (task, wait) => bindAsyncRunner(_throttle.default, task, wait)
};
exports.AsyncUtils = AsyncUtils;