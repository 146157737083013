import PropTypes from "prop-types";
import React from "react";
import styled, {css} from "styled-components";

const LabelProps = {
  small: PropTypes.bool,
  htmlFor: PropTypes.string.isRequired,
};

const LabelContainer = styled.label(
  ({small}) => css`
    background-color: transparent;
    display: inline-block;
    font-size: ${small ? "12px" : "14px"};
    font-weight: ${small ? "600" : "normal"};
    margin-bottom: 4px;
    transition: all 0.3s;
    border: 1px solid transparent;
    border-radius: 5px;
    padding: 4px 5px;
    color: #181b1d;

    &.error {
      background-color: rgb(239, 196, 204);
      color: rgb(175, 26, 48);
    }
  `,
);

export const Label = ({children, error, ...props}) => (
  <LabelContainer className={error ? "error" : ""} {...props}>
    {children}
  </LabelContainer>
);

Label.propTypes = LabelProps;
