import {useState} from "react";

import {HeroTop} from "./HeroTop";
import {Features} from "./Features";
import {Footer} from "./Footer";
import {Pricing} from "./Pricing";
import {SignUpModal} from "./SignUpModal";

export const AppLayout = () => {
  const [signUpModalOpen, setSignUpModalOpen] = useState(false);

  const layoutSectionProps = {
    setSignUpModalOpen,
  };

  return (
    <>
      <HeroTop {...layoutSectionProps} />
      <Features {...layoutSectionProps} />
      <Pricing {...layoutSectionProps} />
      <Footer />
      <SignUpModal open={signUpModalOpen} setOpen={setSignUpModalOpen} />
    </>
  );
};
