import styled from "styled-components";

import {Loader} from "./Loader";

const Container = styled.button`
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 1.5px;
  font-weight: 600;
  transition: all 0.4s;

  &:disabled {
    opacity: 0.5;
  }
`;

export const Button = ({children, className, isLoading, variant, ...props}) => (
  <Container
    className={
      variant
        ? `
        rounded-md
        bg-slate-50
        hover:bg-blue-600
        hover:text-slate-50
        focus:bg-blue-600
        focus:text-slate-50
        px-6
        py-4
        text-slate-700
        flex
        flex-row
        ${className}
      `
        : `
        rounded-md
        bg-gradient-to-r
        from-blue-500
        to-blue-800
        hover:from-blue-600
        hover:to-blue-900
        focus:from-blue-600
        focus:to-blue-900
        px-6
        py-4
        text-slate-50
        flex
        flex-row
        ${className}
      `
    }
    {...props}
  >
    {isLoading ? <Loader /> : children}
  </Container>
);
