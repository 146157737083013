import {AnimatePresence} from "framer-motion";
import {GiPartyPopper} from "react-icons/gi";
import {FaUnlock} from "react-icons/fa";
import {IoMdClose} from "react-icons/io";

import {
  ModalBackdrop,
  ModalContentContainer,
  ModalContent,
} from "./styled-components";

import {SignUpForm} from "./SignUpForm";

export const SignUpModal = ({open, setOpen}) => (
  <ModalBackdrop
    className={open ? "modal-open" : ""}
    onClick={() => setOpen(false)}
  >
    <AnimatePresence>
      {open && (
        <ModalContentContainer
          className="
            bg-slate-50
            rounded-lg
            m-6
            shadow-slate-900/30
            shadow-lg
            relative
          "
          initial={{
            opacity: 0,
            scale: 0.25,
          }}
          animate={{
            opacity: 1,
            scale: 1,
            transition: {
              type: "spring",
              delay: 0.25,
              duration: 0.5,
            },
          }}
          exit={{
            opacity: 0,
            scale: 0.25,
            transition: {
              type: "spring",
              duration: 0.5,
            },
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <ModalContent
            initial={{
              opacity: 0,
              y: 15,
            }}
            animate={{
              opacity: 1,
              y: 0,
              transition: {
                type: "spring",
                delay: 0.5,
                duration: 0.75,
              },
            }}
          >
            <div className="p-6 lg:p-8">
              <div
                className="flex w-full justify-between relative"
                style={{zIndex: 100}}
              >
                <h2
                  className="
                    text-sm
                    text-slate-600
                    font-semibold
                    uppercase
                    text-center
                    flex
                    items-center
                  "
                >
                  <FaUnlock className="inline-block mr-3" /> Sign Up For The
                  Free Beta
                </h2>
                <div
                  className="
                    transition
                    text-xl
                    text-slate-400
                    hover:text-slate-700
                    p-2
                    -mt-2
                    -mr-2
                    cursor-pointer
                  "
                  onClick={() => setOpen(false)}
                >
                  <IoMdClose />
                </div>
              </div>
              <div
                className="
                  text-sm
                  text-blue-900
                  bg-blue-100
                  border
                  border-blue-300
                  rounded
                  p-4
                  mt-5
                  block
                "
              >
                <div className="flex content-center">
                  <GiPartyPopper className="text-blue-600 mb-2 text-lg inline-block mr-2 relative -top-1" />
                  <h4 className="uppercase text-xs text-blue-600 inline-block font-semibold">
                    Beta Release
                  </h4>
                </div>
                Sign up for the beta release and share your feedback to help us
                improve our product
              </div>
              <SignUpForm />
            </div>
          </ModalContent>
        </ModalContentContainer>
      )}
    </AnimatePresence>
  </ModalBackdrop>
);
