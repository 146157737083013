import {motion, useAnimation} from "framer-motion";
import {useInView} from "react-intersection-observer";
import {useEffect} from "react";

export const AnimateEntrance = ({
  children,
  delay = 0.15,
  duration = 1.25,
  threshold = 0,
}) => {
  const control = useAnimation();
  const [ref, inView] = useInView({
    threshold,
  });

  const variantDefaults = {
    transition: {
      type: "spring",
      delay,
      duration,
    },
  };

  const animationVariants = {
    visible: {
      opacity: 1,
      y: 0,
      ...variantDefaults,
    },
    hidden: {
      opacity: 0,
      y: 15,
      ...variantDefaults,
    },
  };

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <motion.div
      className="relative"
      ref={ref}
      variants={animationVariants}
      initial="hidden"
      animate={control}
    >
      {children}
    </motion.div>
  );
};
