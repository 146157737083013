import {BiRightArrowAlt} from "react-icons/bi";
import {GoCheck} from "react-icons/go";

import {AnimateEntrance} from "./shared/AnimateEntrance";
import {Button} from "./shared/Button";

export const Pricing = ({setSignUpModalOpen}) => {
  return (
    <section
      className="
      bg-slate-100
      pt-[65px]
      pb-20
      px-6
      relative
      z-0
      overflow-hidden
      -skew-y-6
      -mb-12
      "
    >
      <div className="container max-w-7xl mx-auto skew-y-6 z-0">
        <div className="flex flex-wrap -mx-4">
          <div className="w-full px-4 pb-6">
            <h3 className="text-center text-slate-800 font-semibold uppercase tracking-widest text-sm lg:text-base">
              Pricing
            </h3>
          </div>
        </div>
        <div className="flex flex-wrap justify-center -mx-4">
          <div className="w-full md:w-2/5 lg:w-1/3 px-4 md:px-0">
            <AnimateEntrance threshold={0.25} duration={2}>
              <div
                className="
                    bg-slate-50
                    rounded-xl
                    relative
                    z-10
                    overflow-hidden
                    p-8
                    md:p-10
                    xl:p-12
                    mb-10
                    transition
                    duration-300
                    ease-in-out
                    shadow-xl
                    shadow-slate-400/30
                    hover:shadow-slate-400/40
                    hover:shadow-lg
                    "
              >
                <h3 className="mb-4 text-center text-slate-400 font-bold uppercase tracking-widest text-sm lg:mb-4">
                  User License
                </h3>
                <div className="flex justify-center">
                  <div>
                    <h2 className="font-regular text-slate-800 text-[42px]">
                      <span className="line-through decoration-2">$35</span>{" "}
                    </h2>
                    <span className="text-slate-400 font-medium text-sm relative -top-2 line-through decoration-1">
                      Yearly subscription
                    </span>
                    <div>
                      <span className="text-sm text-blue-700 font-bold">
                        FREE BETA
                      </span>
                    </div>
                    <div className="">
                      <ul className="mt-5 mb-6 pt-6 border-t border-slate-300">
                        <li className="flex flex-row mb-1 text-slate-600">
                          <GoCheck className="mr-3 relative top-1 text-green-600" />{" "}
                          All features
                        </li>
                        <li className="flex flex-row mb-1 text-slate-600">
                          <GoCheck className="mr-3 relative top-1 text-green-600" />{" "}
                          Maintenance &amp; feature updates
                        </li>
                        <li className="flex flex-row mb-1 text-slate-600">
                          <GoCheck className="mr-3 relative top-1 text-green-600" />{" "}
                          Customer support
                        </li>
                      </ul>
                      <div>
                        <Button onClick={() => setSignUpModalOpen(true)}>
                          Sign Up For the Free Beta
                          <BiRightArrowAlt className="ml-2 text-base" />
                        </Button>
                      </div>
                      <div className="mt-6 text-slate-400 text-sm">
                        * Beta version available for a limited time. After the
                        beta period ends, you may only access this software
                        using a license.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </AnimateEntrance>
          </div>
        </div>
      </div>
    </section>
  );
};
