import {ReactComponent as LogoSVG} from "../assets/logo-dark.svg";

export const Footer = () => (
  <div className="p-6 bg-slate-100 text-slate-400 border-t border-slate-300 relative">
    <div className="container mx-auto flex flex-col sm:flex-row sm:justify-between">
      <div className="flex w-full justify-end sm:w-auto sm:block">
        <LogoSVG className="relative -top-6 left-12 sm:-left-8 sm:-top-6 scale-75" />
      </div>
      <div>
        <span className="text-xs relative top-3 sm:-top-2">
          2018-{new Date().getFullYear()} Azara Interactive LTD.
        </span>
      </div>
    </div>
  </div>
);
