import {Oval} from "react-loader-spinner";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";

const DEFAULT_SIZE = 17;

const LoaderProps = {
  color: PropTypes.string,
  size: PropTypes.number,
};

const LoaderContainer = styled.div(
  ({height, width}) => css`
    display: flex;
    overflow: visible;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -0.5px;
    height: ${(height || 0) - 3.5}px;

    & svg {
      height: ${height};
      width: ${width};
    }

    & svg circle {
      stroke: rgba(255, 255, 255, 0.5);
    }
  `,
);

export const Loader = ({size = DEFAULT_SIZE, ...props}) => {
  const dimensions = {height: size, width: size};

  return (
    <LoaderContainer {...dimensions} {...props}>
      <Oval color="white" {...dimensions} {...props} />
    </LoaderContainer>
  );
};

Loader.propTypes = LoaderProps;
