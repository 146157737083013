import {AnimatePresence} from "framer-motion";
import {GiPartyPopper} from "react-icons/gi";
import {FaUnlock} from "react-icons/fa";
import {MdErrorOutline} from "react-icons/md";
import {useFormik} from "formik";
import {useState} from "react";
import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";
import Schema from "joi";

import {validate, wait} from "@resound/utils";

import {Button} from "../shared/Button";
import {Label} from "../shared/Label";

import {
  FormSubmissionResult,
  SuccessMessage,
  ErrorContainer,
} from "./styled-components";

export const SignUpForm = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formError, setFormError] = useState(null);

  const defaultFormFields = {
    email: "",
    honeypot: "",
  };

  const {
    dirty,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    values,
  } = useFormik({
    initialValues: defaultFormFields,
    validate: validate(
      Schema.object({
        honeypot: Schema.string().allow(""),
        email: Schema.string()
          .email({tlds: {allow: false}})
          .required(),
      }),
    ),
    onSubmit: async (values) => {
      if (isLoading) return;

      setIsLoading(true);

      try {
        const body = omit(values, ["honeypot"]);
        const headers = {
          "Content-Type": "application/json",
        };

        const hostname = process.env.REACT_APP_RESOUND_API_HOST;

        if (!isEmpty(values.honeypot)) {
          body.honeypot = values.honeypot;
        }

        // eslint-disable-next-line no-unused-vars
        const [_, response] = await Promise.all([
          wait(650),
          fetch(`//${hostname}/beta/register`, {
            method: "POST",
            headers,
            body: JSON.stringify(body),
          }),
        ]);

        if (response.status === 400) {
          throw new Error(`The email you submitted is invalid`);
        } else if (response.status === 401) {
          throw new Error(`${values.email} is already registered`);
        } else if (response.status >= 300) {
          throw new Error("There was an unexpected error");
        }

        setFormError(null);
        setIsSubmitted(true);
      } catch (error) {
        setFormError(error.message);
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <form
      className="mt-2"
      autoComplete="off"
      name="beta-registration"
      noValidate
    >
      <FormSubmissionResult className={`${isSubmitted ? "is-submitted" : ""}`}>
        <div>
          <SuccessMessage>
            <div className="icon-container">
              <GiPartyPopper className="icon" />
            </div>
            <div>
              <h3>You're in!</h3>
              <h4>Thanks for participating in our early access program.</h4>
              <h4>
                We'll send you an e-mail when the beta release is ready for
                download.
              </h4>
            </div>
          </SuccessMessage>
        </div>
      </FormSubmissionResult>
      <AnimatePresence>
        {formError && (
          <ErrorContainer
            initial={{
              scale: 0,
              opacity: 0,
            }}
            animate={{
              scale: 1,
              opacity: 1,
              transition: {
                type: "spring",
                duration: 0.5,
              },
            }}
            exit={{
              scale: 0,
              opacity: 0,
              transition: {
                type: "spring",
                duration: 0.5,
              },
            }}
            className="
              text-sm
              text-rose-800
              bg-rose-200
              rounded
              mt-4
            "
          >
            <div className="p-4 flex items-center">
              <MdErrorOutline className="mr-2 text-lg" />
              {formError}
            </div>
          </ErrorContainer>
        )}
      </AnimatePresence>
      <div className="mt-4">
        <Label
          htmlFor="beta-registration-form-email"
          error={touched.email && errors.email}
          small
        >
          Email
        </Label>
      </div>
      <input
        className="text-slate-700 w-full border hover:border-blue-400 focus:border-blue-500 border-slate-300 rounded-md px-3 py-2 transition"
        type="email"
        name="email"
        id="beta-registration-form-email"
        placeholder="example@email.com"
        value={values.email}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      {/**
       * This is a "honeypot" field intended to throw off spam. If they fill out
       * this field, we include it in the payload which then gets invalidated by
       * the api.
       */}
      <input
        style={{
          opacity: 0,
          position: "absolute",
          zIndex: -1,
          left: -99999,
          top: -99999,
          pointerEvents: "none",
        }}
        type="text"
        name="full-name"
        id="beta-registration-form-full-name"
        value={values.honeypot}
        onChange={handleChange}
      />
      <div className="w-full">
        <Button
          className="mt-6 w-full flex justify-center"
          type="submit"
          isLoading={isLoading}
          disabled={!dirty || !isEmpty(errors) || isSubmitted}
          onClick={handleSubmit}
        >
          <FaUnlock className="mr-3 top-[2px] relative" /> Sign Up
        </Button>
      </div>
      <div className="mt-2 text-slate-400 text-sm">
        *Beta version available for a limited time
      </div>
    </form>
  );
};
