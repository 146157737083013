import {BiTime} from "react-icons/bi";
import {FaKeyboard, FaSearch, FaRandom, FaLightbulb} from "react-icons/fa";
import {MdOutlineSync} from "react-icons/md";
import {RiDragDropLine} from "react-icons/ri";
import styled from "styled-components";

import {AnimateEntrance} from "./shared/AnimateEntrance";
import {Button} from "./shared/Button";

const Container = styled.div`
  background: linear-gradient(45deg, #1c2c55 0, #1e3878 100%);
  padding-top: 210px;

  @media (min-width: 640px) {
    padding-top: 310px;
  }
`;

const TimeIcon = styled(BiTime)`
  position: relative;
  top: 1px;
`;

const Feature = ({icon, title, children}) => (
  <AnimateEntrance threshold={0.25}>
    <dt>
      <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-slate-900 text-white">
        {icon}
      </div>
      <p className="ml-16 text-lg leading-6 font-bold text-slate-100">
        {title}
      </p>
    </dt>
    <dd className="mt-2 ml-16 text-base text-blue-300">{children}</dd>
  </AnimateEntrance>
);

export const Features = ({setSignUpModalOpen}) => (
  <Container className="pb-12 sm:pb-8 lg:pb-10 -skew-y-6 z-0">
    <div className="skew-y-6  z-0">
      <div className="container mx-auto px-6 sm:px-8 lg:px-10">
        <h3 className="text-center text-blue-100 font-semibold uppercase tracking-widest text-sm lg:text-base">
          Features
        </h3>

        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            <Feature icon={<FaLightbulb />} title="Smart auto-tagging">
              Audio recognition based tagging organizes your files by{" "}
              <i>how they sound</i>, not just how they're named or what folder
              they're stored in.
            </Feature>

            <Feature icon={<FaSearch />} title="Fast &amp; powerful search">
              Quickly search your sounds based on their tags, filenames, and
              folders.
            </Feature>

            <Feature icon={<MdOutlineSync />} title="Library sync">
              Automatically adds your existing sounds from Splice and other
              third-party applications.
            </Feature>

            <Feature icon={<FaKeyboard />} title="Global keyboard shortcut">
              Pull up your sounds from anywhere with a global keyboard shortcut
              that lets you access your library at any time.
            </Feature>

            <Feature icon={<RiDragDropLine />} title="Drag-and-drop">
              Drop your sounds into your Digital Audio Workstation or VST
              plugins with an easy to use drag-and-drop interface.
            </Feature>

            <Feature icon={<FaRandom />} title="Shuffle mode">
              Shuffle your search results, get creative, and rediscover
              underutilized sounds.
            </Feature>
          </dl>
        </div>
        <div className="text-center">
          <AnimateEntrance threshold={0.25}>
            <div className="mt-10 mb-3 flex items-center justify-center">
              <Button onClick={() => setSignUpModalOpen(true)} variant>
                <TimeIcon className="mr-3" /> Sign Up For the Free Beta
              </Button>
            </div>
            <div className="mb-4 text-blue-300 text-sm">
              *For a limited time
            </div>
          </AnimateEntrance>
        </div>
      </div>
    </div>
  </Container>
);
