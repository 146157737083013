import {motion} from "framer-motion";
import styled, {css} from "styled-components";

export const ModalBackdrop = styled(motion.div)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.5s;
  display: fixed;
  visibility: hidden;
  opacity: 0;
  justify-content: center;
  align-items: center;
  z-index: 100;
  pointer-events: visible;

  &.modal-open {
    visibility: visible;
    opacity: 1;
  }
`;

export const ModalContentContainer = styled(motion.div)`
  max-width: 460px;
`;

export const ModalContent = styled(motion.div)``;

export const FormSubmissionResult = styled.div(
  () => css`
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: all 0.5s;
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    border-radius: 8px;
    z-index: 1;
    transform: scale(0);
    background: #fff;
    box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.15);
    border: 1px solid transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;

    &.is-submitted {
      opacity: 1;
      right: 0;
      bottom: 0;
      transform: scale(1);
    }
  `,
);

export const SuccessMessage = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    transition: all 0.4s;
    margin: 31px;

    .icon-container {
      overflow: visible;
      padding: 24px;
      background: #eee;
      border-radius: 52px;
      margin-top: 32px;
    }

    .icon {
      font-size: 54px;
      overflow: visible;
    }

    h3 {
      font-size: 22px;
      margin-top: 16px;
      margin-bottom: 4px;
      color: #3a4145;
    }

    h4 {
      font-size: 14px;
      color: #576267;
      margin-bottom: 8px;
    }
  `,
);

export const ErrorContainer = styled(motion.div)``;
